import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static values = {
        term: String,
    }

  search() {
    if (window.CommandBar) {
      window.CommandBar.openCopilot({ query: this.termValue });
    } else {
      console.debug(`CommandBar not configured, query: "${this.termValue}"`);
    }
  }
}